import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from "react";
import { isMobile } from 'react-device-detect';

import './../App.css';
import './IdeaPage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import CusCard from '../components/CusCard';
import { gamesPage } from '../constants/pageName';
import { gameVideoLink } from '../constants/links';

import aiHacker from '../assets/images/gameList/aiHackerCardImg.png';
import initialEdge from '../assets/images/gameList/initialEdge.png';

import { Box, Chip } from '@mui/material';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const DesktopChip = () => {return (<Chip icon={<LaptopWindowsIcon/>} label='Desktop'/>)};
const MobileChip = () => {return (<Chip icon={<PhoneIphoneIcon/>} label='Mobile'/>)};
const OfflineChip = () => {return (<Chip icon={<DownloadForOfflineIcon/>} label='Offline'/>)};

const ListOfGamePageBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

  return (
    <div className='step-container'>
      <h1>Play My Developed Game</h1>
      <hr />
      <Box sx={{ mt: 1, mb: 1 }}>
        Here are some of my games created with <b>Unity</b> and <b>Blender</b>. Some can be played on web browser, some can only played offline. 
        Please be noted that some contents are only available in certain platform.
        System detected device: { isMobile ? <MobileChip/> : <DesktopChip/> }
      </Box>
      <CusCard 
        imgUrl={aiHacker} 
        gameUrl={gamesPage.aiHackGame}
        title='AI Algorithm Hacker'
        intro='In GamePlay mode, player need to hack into different devices and disable it using different AI algorithms. Player would also need to kill the enemies and proceed to next level. Hacking Mode is a time attack mode where player can practice different AI algorithms here.'
        platform={[<DesktopChip key='desktop'/>]}
        videoUrl={gameVideoLink.aiGamePlay}
      />
      <CusCard 
        imgUrl={initialEdge} 
        webUrl={gamesPage.initialEdge}
        title='Initial Edge'
        intro='The idea is inspire by Monster Hunter. Player can travel in the game world and kill different monster in different quests. This is also my first unity project.'
        platform={[<OfflineChip key='offline'/>]}
      />
    </div>
  );
}

const ListOfGamePage = () => {
  return(
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container' style={{ minHeight: '1500px' }}>
          <ListOfGamePageBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default ListOfGamePage;