import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from "react";

import './../App.css';
import './IdeaPage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import CusCard from '../components/CusCard';
import { myWebpages } from '../constants/pageName';

import stpp from '../assets/images/webList/stpp.png';
import sortingAlgorithm from '../assets/images/webList/sortingAlgorithm.png';
import onlineShopping from '../assets/images/webList/onlineShopping.png';

const stppTools = ['React TS', 'MUI', 'Koltin', 'Spring Boot', 'JPA', 'Entity Framework', 'PostgreSQL', 'Docker', 'PM2', 'Nginx'];
const sortingAlgorithmsTools = ['HTML', 'CSS', 'JS', 'AJAX', 'Google Charts', 'PHP', 'MySQL', 'Apache', 'phpMyAdmin'];
const onlineShoppingTools = ['HTML', 'CSS', 'JS', 'PHP', 'MySQL', 'Apache', 'phpMyAdmin'];

const ListOfWebPageBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

  return (
    <div className='step-container'>
      <h1>Visit My Other Webpages</h1>
      <hr />
      <p>
        The websites are currently hosting on <b>AWS EC2</b> server:
      </p>
      <CusCard 
        imgUrl={stpp} 
        webUrl={myWebpages.stpp}
        title='STPP'
        intro='Special Tasks Pairing Platform (STPP) is a web application that allows users to pair with other users who share common interests.'
        webUserName='N/A'
        webPW='N/A'
        listOfTools={stppTools}
      />
      <CusCard 
        imgUrl={sortingAlgorithm} 
        webUrl={myWebpages.sortingAlgorithms}
        title='Sorting Algorithm'
        intro='The website includes step by step animation explanation on sorting algorithms and different online exercises. It also has File System that support CRUD operation with txt files, as well as Comment Section, real-time Chat Room and Leaderboard. Only the Admin account can view other users.'
        webUserName='Admin'
        webPW='admin'
        listOfTools={sortingAlgorithmsTools}
      />
      <CusCard 
        imgUrl={onlineShopping} 
        webUrl={myWebpages.onlineShopping}
        title='Dreamland'
        intro='The website includes the functions of Account Management, Inventory Management and Purchasing Process. User can also upload the products to the server and other customer can view and buy it online.'
        webUserName='User'
        webPW='user'
        listOfTools={onlineShoppingTools}
      />
    </div>
  )
}

const ListOfWebPage = () => {
  return(
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container' style={{ minHeight: '1500px' }}>
          <ListOfWebPageBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default ListOfWebPage;