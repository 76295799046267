import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import GamesIcon from '@mui/icons-material/Games';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { Link } from 'react-router-dom';
import { Button, Chip, Stack } from '@mui/material';

type cusCardProps = {
  imgUrl: string,
  gameUrl?: string,
  webUrl?: string,
  webUserName?: string,
  webPW?: string,
  title: string,
  intro: string,
  platform?: any[];
  videoUrl?: string,
  listOfTools?: string[],
}

const CusCard = (props: cusCardProps) => {
  return (
    <Card sx={{ display: 'flex', marginBottom: 3, minHeight: '230px' }}>
      <CardMedia
        component='img'
        sx={(theme) => ({ width: 250, [theme.breakpoints.down('md')]: {width: 0}})}
        image={props.imgUrl}
        alt={props.title}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          {
            props.webUrl !== undefined
            ?
            <a href={props.webUrl!} target='_blank' rel='noreferrer'>
              <Typography variant='h5' component='div'>
                {props.title}
              </Typography>
            </a>
            :
            <Link to={props.gameUrl!} target='_blank'>
              <Typography variant='h5' component='div'>
                {props.title}
              </Typography>
            </Link>
          }
          <Typography variant='subtitle1' component='div'>
            {props.intro}
          </Typography>
          <br/>
          {
            props.webUrl !== undefined && props.webUserName !== undefined
            ?
            <Typography variant='subtitle2' component='div'>
              Username: {props.webUserName}
              <br/>
              Password: {props.webPW}
            </Typography>
            :
            <Typography variant='subtitle2' component='div'>
              Available platform: {props.platform}
            </Typography>
          }
          {
            props.webUrl !== undefined &&
            <Stack
              direction='row'
              sx={{
                mt: 1,
                width: '100%',
                flexWrap: 'wrap',
                '& > *': {
                  mt: 1, ml: 1,
                },
              }}
            >
              {props.listOfTools?.map((tool, index) => (
                <Chip
                  key={index}
                  label={tool}
                  sx={{ borderRadius: '10%', }}
                />
              ))}
            </Stack>
          }
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          {
            props.webUrl !== undefined
            ?
            null
            :
            <Link to={props.gameUrl!} target='_blank'>
              <Button>
                <GamesIcon /> Play 
              </Button>
            </Link>
          }
          {
            props.videoUrl !== undefined 
            ?
            <a href={props.videoUrl!} target='_blank' rel='noreferrer'>
              <Button>
                <SmartDisplayIcon /> DEMO
              </Button>
            </a>
            :
            null 
          }
        </Box>
      </Box>
    </Card>
  );
}

export default CusCard;