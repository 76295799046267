import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { useEffect, useRef, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';

import './../App.css';
import './SectionSkills.css';
import MainBtn from '../components/MainBtn';
import { mainPage } from '../constants/pageName';
import { horizontalChartsAnimation } from '../utils/horizontalChartsAnimation';
import useOnScreen from '../hook/useOnScreen'; 
import { ButtonBase } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

type skillPieChartsProps = {
  fillPercentage: number,
  color: string, 
  title: string,
  titleStartIcon?: any,
};

const SkillPieCharts = (props: skillPieChartsProps) => {
  const width = 60;

  return (
    <div style={{ marginInline: '20px' }}>
      <p>{props.titleStartIcon} {props.title}</p>
      <PieChart
        style={{height: '150px'}}
        data={[{ value: 1, key: 1, color: props.color }]}
        reveal={props.fillPercentage}
        lineWidth={20}
        background='#bfbfbf'
        lengthAngle={360}
        rounded
        animate
        label={() => `${props.fillPercentage}%`}
        labelPosition={width / 2}
        labelStyle={{
          fill: props.color,
          pointerEvents: 'none',
        }}
        animationDuration={3000}
      />
  </div>
  )
};

const skillWebColor = '#49E';
const skillMobileColor = '#ef5e5e';
const skillBackendColor = '#26a69a';
const skillGameColor = '#FFA500';

const SectionSkills = () => {
  horizontalChartsAnimation();

  const [fillPercentage, setFillPercentage] = useState([0, 0, 0, 0]);
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const navigate = useNavigate();
  const navigateToWeb = () => { navigate(mainPage.webDev); };
  const navigateToWebList = () => { navigate(mainPage.webList); };
  const navigateToGame = () => { navigate(mainPage.gameDev); };
  const navigateToGameList = () => { navigate(mainPage.gameUnity); };

  // Update fillPercentage only once when isVisible changes to true
  useEffect(() => {
    if (isVisible) {
      setFillPercentage([85, 72, 59, 33]);
    } else {
      setFillPercentage([0, 0, 0, 0]);
    }
  }, [isVisible]);

  const [hoverWeb, setWebHover] = useState(false);
  const hoverWebStyle: React.CSSProperties = {
    color: hoverWeb ? skillWebColor : 'inherit',
    fontWeight: hoverWeb ? 'bold' : 'inherit',
    border: hoverWeb ? `2px solid ${skillWebColor}` : '2px solid transparent',
    borderRadius: '10%',
  };
  const [hoverGame, setGameHover] = useState(false);
  const hoverGameStyle: React.CSSProperties = {
    color: hoverGame ? skillGameColor : 'inherit',
    fontWeight: hoverGame ? 'bold' : 'inherit',
    border: hoverGame ? `2px solid ${skillGameColor}` : '2px solid transparent',
    borderRadius: '10%',
  };

	return (
		<>
			<div className='pageSection' id='sectionSkill'>
        <div className = 'sectionSkillsub'>
          <h2 id='hSkill'>Skill</h2>
          <p id='pSkill'>
            I have learnt different programming languages such as, <b>Python</b>, <b>Java</b>, <b>C</b>, <b>Koltin</b> and <b>Swift</b>.
            I also have some foundation on lower level programming language like <b>MIPS</b>.
            Apart from that, I have studied in different aspects like
            <b>OOP</b>,
            <b>Data Structure</b>,
            <b>Web Application</b>,
            <b>Game Design & Development</b>,
            <b>HCI</b>,
            <b>Operation System</b>,
            <b>Algorithm Design</b>,
            <b>E-business</b>,
            <b>CRM</b>,
            <b>Networking</b>,
            <b>Software Engineering</b>,
            <b>Database System</b>,
            <b>Big Data</b>,
            <b>Machine Learning</b>
            and 
            <b> Artificial Intelligence</b> etc.
            <br/><br/>
            Click on <b>Web</b> or <b>Game</b> Pie Chart to view more:
          </p>

          { /* Pie Charts */ }
          <div ref={ref} className='skill-charts-container'>
            <ButtonBase 
              onClick={navigateToWebList}
              onMouseEnter={() => setWebHover(true)}
              onMouseLeave={() => setWebHover(false)}
              style={{ padding: 10, ...hoverWebStyle}}  
            >
              <SkillPieCharts title='Web' titleStartIcon={<WebIcon fontSize='large'/>} fillPercentage={fillPercentage[0]} color={skillWebColor}/>
            </ButtonBase>
            <SkillPieCharts title='Mobile' fillPercentage={fillPercentage[2]} color={skillMobileColor}/>
            <SkillPieCharts title='Backend' fillPercentage={fillPercentage[1]} color={skillBackendColor}/>
            <ButtonBase 
              onClick={navigateToGameList}
              onMouseEnter={() => setGameHover(true)}
              onMouseLeave={() => setGameHover(false)}
              style={{ padding: 10, ...hoverGameStyle}}  
            >
              <SkillPieCharts title='Game' titleStartIcon={<VideogameAssetIcon fontSize='large'/>} fillPercentage={fillPercentage[3]} color={skillGameColor}/>
            </ButtonBase>
          </div>

          <br/>

          { /* Horizontal Bars */ }
          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '200px'}}>
              <b>Web Application</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='93' className='bar deepOrangeBar'></div><b>HTML</b></li>
                <li><div data-percentage='88' className='bar blueBar'></div><b>CSS</b></li>
                <li><div data-percentage='79' className='bar orangeBar'></div><b>JavaScript</b></li>
                <li><div data-percentage='81' className='bar lightBlueBar'></div><b>React</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '200px'}}>
              <b>Mobile Application</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='55' className='bar greenBar'></div><b>Android (Java)</b></li>
                <li><div data-percentage='60' className='bar greyBar'></div><b>iOS (Swift)</b></li>
                <li><div data-percentage='68' className='bar lightBlueBar'></div><b>React Native</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '200px'}}>
              <b>Backend Development</b><br/><br/>
              <ul className='bars'>
                <li><div data-percentage='78' className='bar greenBar'></div><b>Spring Boot</b></li>
                <li><div data-percentage='69' className='bar greyBar'></div><b>Node JS</b></li>
                <li><div data-percentage='62' className='bar purpleBar'></div><b>PHP</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '200px'}}>
              <b>Game Development</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='63' className='bar greyBar'></div><b>Unity</b></li>
                <li><div data-percentage='52' className='bar orangeBar'></div><b>Blender</b></li>
              </ul>
            </div>
          </div>

          <div className='chartBarsWrap chartBarsHorizontal'>
            <div className='chartBars' style={{height: '300px'}}>
              <b>Programming Language</b><br/><br/>
              <ul className='bars'>  
                <li><div data-percentage='63' className='bar amber'></div><b>Python</b></li>
                <li><div data-percentage='65' className='bar redBar'></div><b>Java</b></li>
                <li><div data-percentage='68' className='bar blueGreyBar'></div><b>C/C#</b></li>
                <li><div data-percentage='52' className='bar lightBlueBar'></div><b>VB</b></li>
                <li><div data-percentage='47' className='bar deepOrangeBar'></div><b>Swift</b></li>
                <li><div data-percentage='67' className='bar purpleBar'></div><b>Kotlin</b></li>
              </ul>
            </div>
          </div>

          <div id='pSkill2'>
            <ul>
              <li>
                <p>
                  Familiar with both <b>Windows</b> and <b>MacOS</b>. 
                  Also have many years of experience on <b>Linux OS</b>, mostly related to deploying API server and do the network or DB configurations on Linux OS.
                </p>
              </li>
              <li>
                <p>Familiar with <b>AWS S3</b> and <b>EC2</b> services.</p>
              </li>
              <li>
                <p>Have some experience on using the Machine Learning Framework like <b>Tensorflow</b>, <b>Keras</b> and <b>PyTorch</b>.</p>
              </li>
              <li>
                <p>Microsoft Word, PowerPoint, Excel.</p>
              </li>
              <li>
                <p>Basic video editing and basic PhotoShop skills.</p>
              </li>
            </ul>
            <p>You can also learn more of my Web Application and Game Development skills by clicking the two button below.</p>
          </div>
        </div>
			</div>

			<div className = 'buttonWrapper'>
        <MainBtn text='Create a Website' onClick={() => navigateToWeb()}/>
        <MainBtn text='Make a Game' onClick={() => navigateToGame()}/>
			</div>
		</>
	)
}

export default SectionSkills;