import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';

import './../App.css';
import './SectionSchedule.css';
import MaterialTable from '../components/MaterialTable';

const SectionSchedule = () => {
	return (
    <div className='pageSection' id='sectionSchedule'>
      <div className='sectionSchedulesub'>
        <h2 id='hSchedule'>Schedule</h2>
        <p id='pSchedule' style={{textAlign: 'center'}}>Timetable</p>
        <MaterialTable/>
        <br/>
      </div>
    </div>
	)
}

export default SectionSchedule;